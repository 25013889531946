export const ENV_PREFIX = "VITE_";

export const RESPONSE_STATUS = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    CONFLICT: 409,
    INTERNAL_SERVER_ERROR: 500,
    UNPROCESSABLE_ENTITY: 422
};

export const ENV = {
    PROD: "prod",
    PRE_PROD: "pre-prod",
    DEV: "dev"
};

export const STORAGE = {
    AUTH: "auth",
    ACCEPT_CONDITION: "acceptCondition",
    ACTIVITY: "lastActivity",
    INTERVAL: "lastInterval",
    CONFIRM_ACCOUNT_TEL: "confirmAccountTel",
    CLIENT_CONFIG: "clientConfig",
    POSITION: "position",
    FIRST_TIME: "firstTime",
    PHONE_NUMBER: "phoneNumber",
    SECONDARY_NUMBER_FEATURE: "secondaryNumberFeature",
    CARD_WALLET_FEATURE: "cardWalletFeature",
    APP_AVAILABLE: "appAvailable",
    ALREADY_SEE_PRIVACY_POLICY: "already_see_privacy_policy",
    ALERT_COMPLETE_PROFILE: "alert_complete_profile",
    TRANSFER_FORM_DATA: "transferFormData",
    HIDE_TRANSFER_KYC_VALIDATED_MESSAGE: "hideTransferKycValidateMessage",
    HIDE_TRANSFER_CARD_KYC_VALIDATED_MESSAGE:
        "hideTransferCardKycValidatedMessage",
    ALREADY_SEE_ALERT_BJ_NUMBER_CHANGE: "alreadySeeAlertBjNumberChange"
};

export const COUNTRY = [
    {
        indicative: "+229",
        name: "Bénin",
        shortName: "bj"
    },
    {
        indicative: "+225",
        name: "Côte d'Ivoire",
        shortName: "ci"
    },
    /*{
        indicative: "+237",
        name: "Cameroun",
        shortName: "cm"
    },*/
    {
        indicative: "+221",
        name: "Sénégal",
        shortName: "sn"
    },
    {
        indicative: "+223",
        name: "Mali",
        shortName: "ml"
    },
    {
        indicative: "+228",
        name: "Togo",
        shortName: "tg"
    },
    {
        indicative: "+226",
        name: "Burkina-Faso",
        shortName: "bf"
    }
    /*{
        indicative: "+224",
        name: "Guinée",
        shortName: "gn"
    },
    {
        indicative: "+234",
        name: "Nigéria",
        shortName: "ng"
    }*/
];

export const WALLET_FILES = {
    EXPRESSO: "expresso.png",
    FREE_MONEY: "free-money.png",
    MOOV: "moov.png",
    MTN: "mtn.png",
    ORANGE_MONEY: "orange-money.png",
    T_MONEY: "t-money.jpeg",
    WAVE: "wave.png",
    WIZALL: "wizall.png",
    E_MONEY: "e-money.png",
    PAYMENT_CARD: "visa-mastercard-card",
    DEFAULT_WALLET: "default-wallet.png"
};

export const DEFAULT_COUNTRY_INDICATIVE = "+229";

export const DEFAULT_COUNTRY_CODE = "bj";

export const DEFAULT_FORMAT = "DD-MM-YYYY";

// export const DATE_TIME_FORMAT = "DD-MM-YYYY hh:mm:ss";

// export const DATE_TIME_FORMAT_W_S = "DD-MM-YYYY hh:mm";

export const SECOND_DATE_TIME = "DD MMM YYYY hh:mm";

// export const INPUT_DATE_FORMAT = "DD/MM/YYYY";

export const FILE_NAME_FORMAT = "YYYY-MM-DD HH.mm.ss";

export const REGEX = {
    PIN: /^[0-9]{4}$/,
    CVV: /^[0-9]{3}$/,
    NUMBER: /^[0-9]*$/,
    CHARACTER: /\D/g,
    CHARACTER_INCLUDE_ONLY_CHARACTER: /^\d+$/,
    EXPIRATION_DATE: /^\d{2}\/\d{2}$/
};

export const DEV_HOST = "http://localhost:5173";

export const WALLET = {
    MTN_BENIN: "Momo Benin",
    MOOV_BENIN: "MOOV BENIN",
    ORANGE_SENEGAL: "Orange Money Senegal",
    WAVE_SENEGAL: "Wave",
    WIZALL_SENEGAL: "WIZALL SENEGAL",
    "E-MONEY_SENEGAL": "E-MONEY",
    FREE_MONEY_SENEGAL: "FREE MONEY SENEGAL"
};

export const LIMIT_AMOUNT = 100000;

export const FILE_EXTENSION = {
    IMAGE: ["jpeg", "jpg", "png", "gif", "webp"],
    PDF: "pdf"
};

export const OTP_CODE_MESSAGES = [
    {
        slug: "orange-money-senegal",
        code: "#144#391#",
        fullCode: "#144#391*code_pin#"
    },
    {
        slug: "orange-money-ci",
        code: "#144*82#",
        fullCode: "#144*82# option 2"
    },
    {
        slug: "orange-money-burkina",
        code: "*144*4*6#",
        fullCode: "*144*4*6*montant#"
    },
    {
        slug: "orange-money-mali",
        code: "#101#77#",
        fullCode: "#101#77#"
    }
];

export const MAX_PIN = 4;

export const CVV_MAX = 3;

export const LIMIT_DATA = 5;

export const EXPIRATION_DATE_MAX = 7;

export const ANDROID_URL =
    "https://play.google.com/store/apps/details?id=com.paydunya.mydunya_live_2";

export const IOS_URL = "https://apps.apple.com/dk/app/mydunya/id6475684818";

export const WALLET_ENABLE_DISABLE = "channel-notification-event";

export const KYC_SUBMIT_REVIEW = "submit_review";

export const OM_WALLET_SLUG = "orange-money-senegal";
