import { useLayoutEffect } from "react";

import { STORAGE } from "../constants";
import { storage } from "../helpers";
import useAuth from "../hooks/useAuth.ts";
import useModal from "../hooks/useModal.ts";

import CardToWalletAvailable from "./CardToWalletAvailable.tsx";
import AlertBjNumberChange from "./modals/AlertBjNumberChange.tsx";
import CGUUpdateModal from "./modals/CGUUpdateModal.tsx";
import InfoDescriptionModal from "./modals/InfoDescriptionModal.tsx";
// import MaintenanceModal from "./modals/MaintenanceModal.tsx";
// import SecondaryPhoneFeatureAvailable from "./SecondaryPhoneFeatureAvailable.tsx";

const AllAlertModals = () => {
    const {
        isOpen: isInfoDesOpen,
        closeModal: closeInfoDesc,
        openModal: openInfoDesc
    } = useModal(false);

    const {
        isOpen: isCguAlertOpen,
        openModal: openCguAlert,
        closeModal: closeCguAlert
    } = useModal(false);

    const { user, isLogin } = useAuth();

    /*const {
        isOpen: isFeatSecondInfoOpen,
        openModal: openFeatSecond,
        closeModal: closeFeatSecond
    } = useModal(false);*/

    const {
        isOpen: isCardToWalletAvailable,
        openModal: openCardWalletInfo,
        closeModal: closeCardWalletInfo
    } = useModal(false);

    const {
        isOpen: isAlertBjNumberChangeOpen,
        openModal: openAlertBjNumberChange,
        closeModal: closeAlertBjNumberChange
    } = useModal(false);

    /*const {
        isOpen: isAvailableInfoOpen,
        openModal: openAvailableInfo,
        closeModal: closeAvailableInfo
    } = useModal(false);*/

    useLayoutEffect(() => {
        const data = storage().getItem(STORAGE.FIRST_TIME);

        if (!data) {
            storage().setItem(STORAGE.FIRST_TIME, JSON.stringify(true));
            openInfoDesc();
        }
    }, [openInfoDesc]);

    /* useLayoutEffect(() => {
        const data = storage().getItem(STORAGE.SECONDARY_NUMBER_FEATURE);

        if (!data) {
            storage().setItem(
                STORAGE.SECONDARY_NUMBER_FEATURE,
                JSON.stringify(true)
            );
            openFeatSecond();
        }
    }, [openFeatSecond]);*/

    useLayoutEffect(() => {
        const data = storage().getItem(STORAGE.CARD_WALLET_FEATURE);

        if (!data && user?.account?.show_card_modal) {
            storage().setItem(
                STORAGE.CARD_WALLET_FEATURE,
                JSON.stringify(true)
            );
            openCardWalletInfo();
        }
    }, [openCardWalletInfo, user?.account?.show_card_modal]);

    /*useLayoutEffect(() => {
        const data = storage().getItem(STORAGE.APP_AVAILABLE);

        if (!data) {
            storage().setItem(STORAGE.APP_AVAILABLE, JSON.stringify(true));
            openAvailableInfo();
        }
    }, [openAvailableInfo]);*/

    useLayoutEffect(() => {
        const alreadySeePrivacyPolicyData = storage().getItem(
            STORAGE.ALREADY_SEE_PRIVACY_POLICY
        );
        const alreadySeePrivacyPolicy = alreadySeePrivacyPolicyData
            ? JSON.parse(alreadySeePrivacyPolicyData)
            : false;

        if (
            isLogin &&
            user &&
            !user?.cgu_validated_at &&
            !alreadySeePrivacyPolicy
        ) {
            storage().setItem(
                STORAGE.ALREADY_SEE_PRIVACY_POLICY,
                JSON.stringify(true)
            );
            openCguAlert();
        }
    }, [isLogin, openCguAlert, user]);

    useLayoutEffect(() => {
        let alreadySeeAlertBjNumberChange = storage().getItem(
            STORAGE.ALREADY_SEE_ALERT_BJ_NUMBER_CHANGE
        );

        alreadySeeAlertBjNumberChange = alreadySeeAlertBjNumberChange
            ? JSON.parse(alreadySeeAlertBjNumberChange)
            : false;

        if (
            isLogin &&
            user &&
            !alreadySeeAlertBjNumberChange &&
            user.account
                ?.show_switch_to_10_digits_in_benin_for_phone_number_modal
        ) {
            storage().setItem(
                STORAGE.ALREADY_SEE_ALERT_BJ_NUMBER_CHANGE,
                JSON.stringify(true)
            );
            openAlertBjNumberChange();
        }
    }, [isLogin, openAlertBjNumberChange, user]);

    return (
        <>
            <CGUUpdateModal
                isOpen={isCguAlertOpen}
                closeModal={closeCguAlert}
            />

            {!isCguAlertOpen && (
                <InfoDescriptionModal
                    isOpen={isInfoDesOpen}
                    closeModal={closeInfoDesc}
                />
            )}

            {/*{!isInfoDesOpen && (
                <SecondaryPhoneFeatureAvailable
                    isOpen={isFeatSecondInfoOpen}
                    closeModal={closeFeatSecond}
                />
            )}*/}

            {!isCguAlertOpen && !isInfoDesOpen && (
                <CardToWalletAvailable
                    isOpen={isCardToWalletAvailable}
                    closeModal={closeCardWalletInfo}
                />
            )}

            {/*{!isInfoDesOpen && !isFeatSecondInfoOpen && (
                <MaintenanceModal
                    isOpen={isAvailableInfoOpen}
                    closeModal={closeAvailableInfo}
                />
            )}*/}

            {!isCguAlertOpen && !isInfoDesOpen && !isCardToWalletAvailable && (
                <AlertBjNumberChange
                    isOpen={isAlertBjNumberChangeOpen}
                    closeModal={closeAlertBjNumberChange}
                />
            )}
        </>
    );
};

export default AllAlertModals;
