import { ClassValue, clsx } from "clsx";
import { parsePhoneNumber } from "libphonenumber-js";
import { twMerge } from "tailwind-merge";

import config, { ClientConfig } from "../config";
import {
    COUNTRY,
    ENV,
    FILE_NAME_FORMAT,
    OTP_CODE_MESSAGES,
    REGEX,
    STORAGE,
    WALLET_FILES
} from "../constants";
import { PlatformEnum, SessionType, UserType } from "../types";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function capitalizeFirstCharacter(str: string) {
    if (str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function scrollToTop() {
    /* eslint-disable */
    if (config.env === ENV.PROD) console.clear();
    /* eslint-enable */

    // Check if the user is already at the top of the page
    if (window.scrollY === 0) {
        return;
    }

    // Scroll to the top of the page
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

export function session(driver: SessionType) {
    if (driver === "localStorage") return localStorage;
    if (driver === "sessionStorage") return sessionStorage;
    return localStorage;
}

export function storage() {
    return session(config.sessionDriver);
}

export function auth() {
    const authValue = storage().getItem(STORAGE.AUTH);
    const allData = authValue ? JSON.parse(authValue) : null;
    let sessionHaseExpire = false;

    if (allData?.tokenData) {
        const expiresAt = allData?.tokenData.expires_at;
        sessionHaseExpire =
            window.dayjs(expiresAt).isValid() &&
            window.dayjs().isAfter(expiresAt);
    }

    return allData
        ? {
              isLogin: !!allData?.isLogin && !sessionHaseExpire,
              user: allData?.user as UserType,
              tokenData: allData?.tokenData
          }
        : null;
}

export function generateArray(n: number) {
    return Array.from({ length: n }, (_, index) => index);
}

export function focusElementById(id: string) {
    const element = document.getElementById(id) as HTMLElement;
    element.focus();
}

export function generateWalletFilePath(walletFileName: string) {
    return `${config.walletFilesPath}/${walletFileName}`;
}

export function getWalletFilePath(hint: string) {
    const formatHint = hint.toLowerCase();
    if (formatHint.includes("expresso"))
        return generateWalletFilePath(WALLET_FILES.EXPRESSO);

    if (formatHint.includes("free-money"))
        return generateWalletFilePath(WALLET_FILES.FREE_MONEY);

    if (formatHint.includes("moov"))
        return generateWalletFilePath(WALLET_FILES.MOOV);

    if (formatHint.includes("mtn"))
        return generateWalletFilePath(WALLET_FILES.MTN);

    if (formatHint.includes("orange-money"))
        return generateWalletFilePath(WALLET_FILES.ORANGE_MONEY);

    if (formatHint.includes("t-money"))
        return generateWalletFilePath(WALLET_FILES.T_MONEY);

    if (formatHint.includes("wave"))
        return generateWalletFilePath(WALLET_FILES.WAVE);

    if (formatHint.includes("wizall"))
        return generateWalletFilePath(WALLET_FILES.WIZALL);

    if (formatHint.includes("e-money"))
        return generateWalletFilePath(WALLET_FILES.E_MONEY);
    if (formatHint.includes(WALLET_FILES.PAYMENT_CARD.toLowerCase())) {
        return generateWalletFilePath(`${WALLET_FILES.PAYMENT_CARD}.png`);
    }
    return generateWalletFilePath(WALLET_FILES.DEFAULT_WALLET);
}

export function formatFileSize(size: number): string {
    const oneMo = 1024;
    if (size < oneMo) {
        return size + "octets";
    } else if (size < oneMo * oneMo) {
        const sizeInKb = (size / oneMo).toFixed(2);
        return sizeInKb + " Ko";
    } else {
        const sizeInMb = (size / (oneMo * oneMo)).toFixed(2);
        return sizeInMb + " Mo";
    }
}

export function getFileExtension(fileName: string): string {
    const dotIndex = fileName.lastIndexOf(".");
    if (dotIndex === -1) {
        return "";
    }
    return fileName.substring(dotIndex + 1);
}

export function getFileSrc(file: File) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = e => {
            resolve(e.target?.result);
        };

        reader.onerror = e => {
            reject(e.target?.error);
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            reject(new Error("Aucun fichie disponible"));
        }
    });
}

export function updateAxiosHost(host: string) {
    window.axios.defaults.baseURL = host + config.auth.apiBasePath + "/";
}

export function loadAuthServiceHost() {
    updateAxiosHost(config.auth.apiHost);
}

export function loadTransferServiceHost() {
    updateAxiosHost(config.auth.transferServiceApiHost);
}

export function getPhoneWithIndicative(phone?: string) {
    if (!phone) return null;
    const phoneData = phone.split(" ");
    if (phoneData.length !== 2) return null;
    return {
        indicative: phoneData[0],
        value: phoneData[1]
    };
}

export function phoneObjectToString(phone: {
    phoneNumber: string;
    indicative: string;
}) {
    return `${phone.indicative} ${phone.phoneNumber}`;
}

export function getAuthBasePath() {
    return `${config.auth.apiHost}${config.auth.apiBasePath}`;
}

export function generateFileName(extension?: string) {
    const fileName = window.dayjs().format(FILE_NAME_FORMAT);

    if (extension) return `${fileName}.${extension}`;

    return fileName;
}

export function getConfig(): ClientConfig | null {
    const storageValue = storage().getItem(STORAGE.CLIENT_CONFIG);
    return storageValue ? JSON.parse(storageValue) : null;
}

export function getCountryByIndicative(countryIndicative: string) {
    const country = COUNTRY.find(item => item.indicative === countryIndicative);

    if (country) return country.shortName;

    return undefined;
}

export function getIndicativeByCountryCode(countryCode: string) {
    const country = COUNTRY.find(
        item => item.shortName.toLowerCase() === countryCode.toLowerCase()
    );

    if (country) return country.indicative;

    return undefined;
}

export function getOtpMessageDataBySlug(slug: string) {
    const element = OTP_CODE_MESSAGES.find(item => item.slug === slug);

    if (element) return element;

    return null;
}

/*export function getTransferBasePath() {
    return `${config.auth.transferServiceApiHost}${config.auth.apiBasePath}`;
}*/

export function refreshClientConfig() {
    const storeConfig = getConfig();
    const clientConfig = { ...config };

    if (storeConfig) {
        clientConfig.locale = storeConfig.locale;
        clientConfig.fakerLocale = storeConfig.fakerLocale;
    }

    storage().setItem(STORAGE.CLIENT_CONFIG, JSON.stringify(clientConfig));
}

export async function srcToObjectFile(
    src: string,
    fileName = generateFileName(),
    typeMime?: string
): Promise<File> {
    const response = await fetch(src);
    const data = await response.blob();

    return new File([data], fileName, { type: typeMime });
}

export class Platform {
    static isAndroid() {
        if (
            (window.navigator.userAgentData?.platform || "")
                .toLowerCase()
                .includes(PlatformEnum.android)
        ) {
            return true;
        }
    }

    static isIos() {
        return [
            "ipad simulator",
            "iphone simulator",
            "ipod simulator",
            "ipad",
            "iphone",
            "ipod"
        ].includes(navigator?.platform?.toLowerCase() || "");
    }
}

export function userPlatformIs(
    value: "win" | "mac" | "linux" | "android" | "ios"
) {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes("android") && value === "android") {
        return true;
    }

    if (
        (userAgent.includes("iphone") ||
            userAgent.includes("ipad") ||
            userAgent.includes("ipod") ||
            userAgent.includes("ios")) &&
        value === "ios"
    ) {
        return true;
    }

    if (userAgent.includes("win") && value === "win") {
        return true;
    }

    if (userAgent.includes("mac") && value === "mac") {
        return true;
    }

    return userAgent.includes("linux") && value === "linux";
}

export function phoneIsValid(phoneNumber: string) {
    const parseValue = parsePhoneNumber(phoneNumber);

    if (!parseValue) return false;

    if (parseValue.countryCallingCode === "229") {
        return parseValue.nationalNumber.length === 10;
    }

    return parseValue.isValid();
}

export function thousandFormater(value: number, separator = ",") {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export function removeThousandSeparator(value: string, separator = ",") {
    const result = value.replaceAll(separator, "");
    return result ? parseInt(result) : "";
}

export function phoneNumberWithIndicativeToObject(phoneNumber: string) {
    const phonePart = phoneNumber.split(" ");

    if (phonePart.length === 0) {
        return {
            indicative: "",
            phone: ""
        };
    }

    return {
        indicative: phonePart[0] || "",
        phone: phonePart[1] || ""
    };
}

export function downloadFile(fileUrl: string, blank = false) {
    const link = document.createElement("a");
    link.href = fileUrl;
    if (blank) link.target = "_blank";
    link.click();
    return link;
}

export function formatValueToCardNumber(value: string) {
    const formatValue = value.replaceAll(" ", "");

    if (!REGEX.CHARACTER_INCLUDE_ONLY_CHARACTER.test(formatValue)) {
        return "";
    }

    return formatValue.replace(/(\d{4})/g, "$1 ").trim();
}

export function removeCharacter(value: string) {
    return value.replace(REGEX.CHARACTER, "");
}

export const clearActivityTimer = (clearStorage = false) => {
    const lastActivityTimer = storage().getItem(STORAGE.ACTIVITY);

    if (lastActivityTimer) {
        clearTimeout(parseInt(lastActivityTimer));

        if (clearStorage) {
            storage().removeItem(STORAGE.ACTIVITY);
        }
    }
};

export function dateIsValid(date: Date) {
    return window.dayjs(date).isValid();
}

export function dateIsSameOrBefore(a: Date, b: Date, unit: "date" | "day") {
    if (!dateIsValid(a) || !dateIsValid(b)) return false;

    return window.dayjs(a).isSameOrBefore(window.dayjs(b), unit);
}
