import Input from "@ui/Input.tsx";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import config from "../config";
import { CVV_MAX, EXPIRATION_DATE_MAX, MAX_PIN, REGEX } from "../constants";
import { cn, formatValueToCardNumber, removeCharacter } from "../helpers";
import { RootState } from "../store";
import { InputProps } from "../types";

import HeLockClosedIcon from "./icons/HeLockClosedIcon.tsx";
import HeLockOpenIcon from "./icons/HeLockOpenIcon.tsx";

interface Props extends InputProps {
    label?: string;
    inputClassName?: string;
    isPin?: boolean;
    isCardNumber?: boolean;
    isCvv?: boolean;
    isExpirationDate?: boolean;
}

const InputField = (props: Props) => {
    const { t } = useTranslation();
    const {
        required = true,
        label,
        error,
        type,
        register,
        inputClassName,
        isPin,
        isCvv,
        isExpirationDate,
        onChange,
        lang = config.locale,
        isCardNumber,
        title = t("Veuillez renseigner ce champs."),
        ...rest
    } = props;

    const [show, setShow] = useState(false);

    const { client } = useSelector((state: RootState) => state.config);

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            if (isPin) {
                if (
                    onChange &&
                    !value.match(REGEX.CHARACTER) &&
                    value.length <= MAX_PIN
                ) {
                    onChange(e);
                }
            } else if (isCardNumber) {
                const eventCopy = { ...e };
                eventCopy.target.value = formatValueToCardNumber(value);

                if (eventCopy.target.value.length <= 19) {
                    onChange && onChange(eventCopy);
                }
            } else if (isCvv) {
                if (
                    onChange &&
                    !value.match(REGEX.CHARACTER) &&
                    value.length <= CVV_MAX
                ) {
                    onChange(e);
                }
            } else if (isExpirationDate) {
                if (value.length <= EXPIRATION_DATE_MAX) {
                    const eventCopy = { ...e };
                    eventCopy.target.value = removeCharacter(
                        eventCopy.target.value
                    );

                    if (onChange) {
                        if (eventCopy.target.value.length > 2) {
                            const month = eventCopy.target.value.substring(
                                0,
                                2
                            );
                            const year = eventCopy.target.value.substring(2);

                            if (parseInt(month, 10) > 12) {
                                eventCopy.target.value = "12 / " + year;
                            } else {
                                eventCopy.target.value =
                                    month.padStart(2, "0") + " / " + year;
                            }
                        }
                        onChange(eventCopy);
                    }
                }
            } else {
                onChange && onChange({ ...e });
            }
        },
        [isCardNumber, isCvv, isExpirationDate, isPin, onChange]
    );

    const inputLang = useMemo(() => {
        return client.locale || lang;
    }, [client.locale, lang]);

    return (
        <div className={cn("w-full", props.className)}>
            {props.label && (
                <label htmlFor={props.id} className="text-sm text-gray-500">
                    {props.label}{" "}
                    {required && <span className="text-red-500">*</span>}
                </label>
            )}

            <div className="w-full relative">
                <Input
                    required={required}
                    className={cn({
                        "mt-2": !!label,
                        [inputClassName || ""]: true
                    })}
                    register={register}
                    error={error}
                    onChange={handleChange}
                    type={
                        type === "password"
                            ? show
                                ? "text"
                                : "password"
                            : type
                    }
                    lang={inputLang}
                    title={title}
                    {...rest}
                />

                {type === "password" && (
                    <button
                        type="button"
                        className={cn({
                            "absolute h-full right-0 top-0 px-8 md:px-4": true,
                            "focus:outline-none text-gray-500": true,
                            "-top-0.5": !!props.error
                        })}
                        onClick={() => setShow(!show)}
                    >
                        {show ? (
                            <HeLockOpenIcon className="text-gray-400" />
                        ) : (
                            <HeLockClosedIcon className="text-gray-400" />
                        )}
                    </button>
                )}
            </div>

            {props.error && (
                <p
                    className="text-xs text-red-500 mt-0.5"
                    data-cy={`error-${rest.name || rest.id}`}
                >
                    {Array.isArray(error) ? (
                        <>{error[0]}</>
                    ) : typeof error === "string" ? (
                        error
                    ) : (
                        error?.message
                    )}
                </p>
            )}
        </div>
    );
};

export default InputField;
