import i18n from "i18next";
import * as yup from "yup";

import { FILE_EXTENSION, REGEX } from "../constants";

interface ParamsType {
    path?: string;
    min?: number;
    max?: number;
}

const requiredMessage = ({ path }: ParamsType) =>
    i18n.t("validation.mixed.required", { path });
const stringMinMessage = ({ min, path }: ParamsType) =>
    i18n.t("validation.string.min", { min, path });
const stringMaxMessage = ({ max, path }: ParamsType) =>
    i18n.t("validation.string.max", { max, path });

/*const numberMinMessage = ({ min, path }: ParamsType) =>
    i18n.t("validation.number.min", { min, path });*/

const amountNumberMinMessage = ({ min, path }: ParamsType) =>
    `${i18n.t("Le montant du transfert doit être de minimum {{min}}", {
        min,
        path
    })} FCFA`;

const IDENTITY_CARD_SIZE = 2 * 1000000;

const bigFileIdentityCardMessage = i18n.t("File size must not exceed 2 MB");
const fileExtensionIdentityCardMessage = i18n.t(
    "Le fichier doit être une image (extensions autorisées: jpg, jpeg, png)"
);

yup.setLocale({
    mixed: {
        default: ({ path }: ParamsType) =>
            i18n.t("validation.mixed.default", { path })
    }
});

const pin = yup
    .string()
    .required(requiredMessage)
    .min(4, stringMinMessage)
    .max(4, stringMaxMessage)
    .matches(REGEX.PIN, {
        message: () =>
            `${i18n.t("{{path}} code must contain 4 digits", {
                path: i18n.t("The secret code")
            })}`
    });

const rules = {
    pin: pin,
    confirmPin: (key: string) =>
        pin.oneOf([yup.ref(key)], () => `${i18n.t("Secret codes must match")}`),
    phone: yup
        .string()
        .required(requiredMessage)
        .matches(REGEX.NUMBER, {
            message: () => i18n.t("Value is not numeric") + ""
        })
        .min(8, () => i18n.t("Veuillez entrer un numéro valide"))
        .max(15, () => i18n.t("Veuillez entrer un numéro valide")),
    fullName: yup
        .string()
        .required(requiredMessage)
        .min(3, () => i18n.t("Le nom doit comporter au moins 3 lettres"))
        .max(80, () => i18n.t("Le nom doit comporter au plus 80 lettres")),
    amount: yup
        .number()
        .required(requiredMessage)
        .min(200, amountNumberMinMessage),
    idNumber: yup.number().required(requiredMessage),
    file: yup.mixed().required(requiredMessage),
    identityCard: yup
        .mixed()
        .required(() => i18n.t("La pièce d'identité est obligatoire."))
        .test("is-valid-type", fileExtensionIdentityCardMessage, value => {
            if (value && value instanceof File) {
                const fileExtension = (
                    value.name.split(".").pop() || ""
                ).toLowerCase();

                return FILE_EXTENSION.IMAGE.includes(fileExtension);
            }
            return false;
        })
        .test("is-valid-size", bigFileIdentityCardMessage, value => {
            if (value && value instanceof File) {
                return value.size <= IDENTITY_CARD_SIZE;
            }
            return false;
        }),
    transferType: yup.string().required(requiredMessage)
};

export default rules;
