import Button from "@ui/Button.tsx";
import { useTranslation } from "react-i18next";

interface Props {
    show?: boolean;
    onClickCancel: () => void;
    onClickContinue: () => void;
}

const LevelOne = (props: Props) => {
    const { t } = useTranslation();
    if (!props.show) return null;

    return (
        <>
            <h5 className="text-center text-sm font-medium my-12">
                {t(
                    "Vous êtes sur le point de supprimer votre compte. Etes vous sûr de vouloir continuer?"
                )}
            </h5>

            <div className="flex items-center gap-6">
                <Button className="w-1/2" onClick={props.onClickContinue}>
                    {t("Continuer")}
                </Button>

                <Button
                    className="w-1/2"
                    onClick={props.onClickCancel}
                    variant="outline"
                >
                    {t("Annuler")}
                </Button>
            </div>
        </>
    );
};

export default LevelOne;
