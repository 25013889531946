import { useTranslation } from "react-i18next";

import { cn } from "../../helpers";

interface Props {
    onClick: () => void;
    isActive: boolean;
    type: "maxit" | "otp" | "simple";
}

const OmSnButton = (props: Props) => {
    const { onClick, type, isActive } = props;

    const { t } = useTranslation();

    return (
        <button
            onClick={onClick}
            className={cn({
                "flex items-center justify-center": true,
                "border p-0.5 rounded-md focus:outline-none": true,
                "focus:ring focus:border-primary focus:ring-primary/20": true,
                "ring border-primary ring-primary/20": isActive
            })}
            type="button"
        >
            {type === "maxit" && (
                <>
                    <img
                        className="hidden xl:block w-full xl:w-auto xl:h-[40px] object-cover object-center rounded-md"
                        src="/images/maxit-web.png"
                        alt=""
                    />

                    <div className="bg-[#EE8131] rounded-md w-full flex items-center justify-center xl:hidden">
                        <img
                            className="xl:hidden w-full max-w-[100px] object-cover object-center rounded-md"
                            src="/images/maxit-mobile.png"
                            alt=""
                        />
                    </div>
                </>
            )}

            {type === "otp" && (
                <div className="h-full bg-gray-100 rounded-md w-full flex items-center justify-center text-sm lg:text-[10px] xl:text-sm xl:font-semibold text-gray-600">
                    {t("Code OTP")}
                </div>
            )}

            {type === "simple" && (
                <img
                    className="h-[20px] lg:h-[10px] xl:h-[20px] object-cover object-center rounded-md"
                    src="/images/wallet/orange-money-two.png"
                    alt=""
                />
            )}
        </button>
    );
};

export default OmSnButton;
