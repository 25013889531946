import { FormEvent, useCallback, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
    DEFAULT_COUNTRY_INDICATIVE,
    RESPONSE_STATUS,
    STORAGE
} from "../constants";
import ENDPOINTS from "../constants/endpoint.ts";
import PAGES from "../constants/pages.ts";
import {
    getIndicativeByCountryCode,
    loadAuthServiceHost,
    phoneIsValid,
    storage
} from "../helpers";
import rules from "../schemas";
import { RootState } from "../store";
import { login } from "../store/authSlice.ts";
import { updateSendDataInTransferForm } from "../store/transferSlice.ts";
import { showSuspendModal } from "../store/uiSlice.ts";
import { KycStatusType } from "../types";

import useManageSchemaValidationError from "./useManageSchemaValidationError.ts";
import useShowInvalidAccountMessage from "./useShowInvalidAccountMessage.tsx";
import useToast from "./useToast.ts";

interface DefaultValueType {
    phone: {
        indicative: string;
        value: string;
    };
    pin?: string;
}

const INVALID_CREDENTIAL_KEY = "phone_pin";
const ACCOUNT_SUSPEND_KEY = "account-suspended";
const ACCOUNT_DELETE_KEY = "account_deleted";
const ACCOUNT_REMOVE = "account_deleted";

const LoginSchema = yup.object().shape({
    phone_with_indicative: rules.phone,
    pin: rules.pin
});

const useLogin = (
    defaultValue?: DefaultValueType | null,
    onSuccess?: (url?: string) => void
) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [invalidCredential, setInvalidCredential] = useState(false);
    const [indicative, setIndicative] = useState(
        defaultValue?.phone?.indicative || DEFAULT_COUNTRY_INDICATIVE
    );
    const [phoneWithoutIndicative, setPhoneWithoutIndicative] = useState(
        defaultValue?.phone.value || ""
    );
    const [pin, setPin] = useState("");
    const printErrorMessage = useShowInvalidAccountMessage();

    const { successToast, errorToast } = useToast();
    const { showErrors, resetErrors, errors, setErrors } =
        useManageSchemaValidationError();
    const dispatch = useDispatch();
    const { position } = useSelector((state: RootState) => state.position);

    useLayoutEffect(() => {
        if (!defaultValue?.phone) {
            const phoneNumberData =
                storage().getItem(STORAGE.PHONE_NUMBER) || "";
            const phoneNumberPart = phoneNumberData.split(" ");

            if (position) {
                const positionIndicative = getIndicativeByCountryCode(
                    position.countryCode
                );

                setIndicative(
                    phoneNumberPart.length === 2
                        ? phoneNumberPart[0]
                        : positionIndicative || DEFAULT_COUNTRY_INDICATIVE
                );
            }

            setPhoneWithoutIndicative(phoneNumberPart[1] || "");
        }
    }, [defaultValue?.phone, position]);

    const onSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            const sendData = {
                phone_with_indicative: phoneWithoutIndicative,
                pin: pin
            };

            LoginSchema.validate(sendData, { abortEarly: false })
                .then(() => {
                    sendData.phone_with_indicative = `${indicative} ${phoneWithoutIndicative}`;
                    const phoneNumber = `${indicative}${phoneWithoutIndicative}`;

                    if (!phoneIsValid(phoneNumber)) {
                        setErrors({
                            ...errors,
                            phone_with_indicative: t("Invalid phone number")
                        });
                        return;
                    }
                    setLoading(true);
                    loadAuthServiceHost();
                    window.axios
                        .post(ENDPOINTS.LOGIN, sendData)
                        .then(response => {
                            resetErrors();
                            invalidCredential && setInvalidCredential(false);
                            successToast(t("Succès de la connexion"));
                            setPhoneWithoutIndicative("");
                            setPin("");
                            const data = response.data.data;
                            const user = data.user;
                            const indicative =
                                user?.phone_with_indicative.split(" ")[0];
                            const phone =
                                user?.phone_with_indicative.split(" ")[1];

                            if (indicative.length && phone.length) {
                                dispatch(
                                    updateSendDataInTransferForm({
                                        indicative,
                                        phone
                                    })
                                );
                            }
                            storage().setItem(
                                STORAGE.PHONE_NUMBER,
                                sendData.phone_with_indicative
                            );

                            dispatch(
                                login({
                                    user,
                                    tokenData: {
                                        ...data.token.token,
                                        accessToken: data.token.accessToken
                                    }
                                })
                            );

                            setTimeout(() => {
                                if (onSuccess) {
                                    const toReview =
                                        user.account?.transfer_kyc_status ===
                                            KycStatusType.to_review ||
                                        user.account?.card_kyc_status ===
                                            KycStatusType.to_review;

                                    onSuccess(
                                        toReview ? PAGES.CORRECT_KYC : undefined
                                    );
                                }
                            }, 100);
                        })
                        .catch(error => {
                            invalidCredential && setInvalidCredential(false);
                            resetErrors();
                            const status = error.response.status;
                            const data = error.response.data.data;
                            const errors = error.response.data.errors;
                            const output = error.response.data.output;

                            switch (status) {
                                case RESPONSE_STATUS.UNPROCESSABLE_ENTITY:
                                    if (
                                        Object.keys(errors).includes(
                                            INVALID_CREDENTIAL_KEY
                                        )
                                    ) {
                                        setInvalidCredential(true);
                                    } else {
                                        setErrors(errors);
                                    }
                                    break;
                                case RESPONSE_STATUS.CONFLICT:
                                    if (data.key === ACCOUNT_SUSPEND_KEY) {
                                        dispatch(showSuspendModal(data.reason));
                                        errorToast(t("Échec de la connexion"));
                                    }

                                    if (data.key === ACCOUNT_REMOVE) {
                                        errorToast(
                                            t("Ce compte à été supprimer"),
                                            undefined,
                                            10000
                                        );
                                    }

                                    if (data.key === ACCOUNT_DELETE_KEY) {
                                        errorToast(
                                            t("Votre compte a été supprimé"),
                                            "",
                                            10000
                                        );
                                    }

                                    printErrorMessage(
                                        sendData.phone_with_indicative,
                                        output.key || ""
                                    );
                                    break;
                                default:
                                    errorToast(
                                        t("Téléphone ou code secret invalide")
                                    );
                                    break;
                            }
                        })
                        .finally(() => setLoading(false));
                })
                .catch(showErrors());
        },
        [
            dispatch,
            errorToast,
            errors,
            indicative,
            invalidCredential,
            onSuccess,
            phoneWithoutIndicative,
            pin,
            printErrorMessage,
            resetErrors,
            setErrors,
            showErrors,
            successToast,
            t
        ]
    );

    return {
        loading,
        errors,
        pin,
        setPin,
        phoneWithoutIndicative,
        setPhoneWithoutIndicative,
        onSubmit,
        invalidCredential,
        indicativeState: {
            value: indicative,
            setValue: setIndicative
        }
    };
};

export default useLogin;
